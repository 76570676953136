import React from "react";

import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/SEO/seo";
import AboutUs from "../../../components/About";

const AboutPage = () => (
	<Layout>
		<SEO
			title="Taxi Services Hamburg | Taxi Services Kiel and Neumünster"
			meta="Book Online Taxi Hamburg to Kiel | Taxi Services in Hamburg"
			description="Want a hassle-free and convenient way to travel from Kiel and Hamburg, Then you can try a reliable  taxi service at Kiel and Nemunster ."
		/>
		<AboutUs language="EN" />
	</Layout>
);

export default AboutPage;
